import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-show-elements"
export default class extends Controller {

  static targets = [ "sourceElement", "targetElement" ]

  connect() {
    this.setTargetElementsVisibility()
  }

  setTargetElementsVisibility() {
    const targetId = this.sourceElementTarget.value
    if (!targetId) {
      this.targetElementTargets.forEach(el => {
        el.hidden = false
      })
    }
    else {
      this.targetElementTargets.forEach(el => {
        if (el.dataset.groupNum == targetId) {
          el.hidden = false
        }
        else {
          el.hidden = true
        }
      })
    }
  }
}
