import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapse"
export default class extends Controller {
  static targets = [ "collapseiconX", "collapseiconY", // old version
                      "arrowiconX", "arrowiconY",
                      "collapsedescription", "collapsebody" ]
  static values  = { openStr: String, closeStr: String, level: Number, group: Number,
                      initialStatus: Boolean }

  initialize() {
    // console.log("[initialize] initialStatusValue=%o", this.initialStatusValue);
    if (this.hasInitialStatusValue) {
      this.status = this.initialStatusValue
    }
    else {
      this.status = false
    }
    // console.log("[initialize] status=%o", this.status);
    this.setStatus()
  }
  connect() {
    // console.log("[connect] status=%o", this.status);
  }
  toggle() {
    // console.log("[toggle]--------------------------")
    // console.log("[toggle-1] status=%o", this.status);
    this.status = !this.status
    this.setStatus()
    // console.log("[toggle-2] status=%o", this.status);
  }
  //toggle() {
  setStatus() {
    // console.log("[setStasus]--------------------------")
    // console.log("[setStatus-1] status=%o", this.status);
    // console.log("[setStatus] %o", this)
    // if (this.hasLevelValue && this.hasGroupValue) {
    //   console.log("level: %o, group: %o", this.levelValue, this.groupValue);
    // }
    if (this.status == true) {
      // console.log("[setStatus] hide->show, %o", this);
      const event = new CustomEvent('closeSubMenu', { detail: this });
      window.dispatchEvent(event);
      // console.log("[setStatus] dispatched event");
      if (this.hasCollapseiconXTarget) {  // old version
        this.collapseiconXTarget.classList.add("rotateX180");
      }
      if (this.hasCollapseiconYTarget) {  // old version
        this.collapseiconYTarget.classList.add("rotateY180");
      }
      if (this.hasArrowiconXTarget) { // not used
        this.arrowiconXTarget.innerHTML = '<i class="bi bi-caret-left">'
      }
      if (this.hasArrowiconYTarget) {
        this.arrowiconYTarget.innerHTML = '<i class="bi bi-caret-up">'
      }
      if (this.hasCollapsedescriptionTarget) {
        this.collapsedescriptionTarget.textContent = `${this.openStrValue}`;
      }
    }
    else {
      // console.log("[setStatus] show->hide, %o", this)
      if (this.hasCollapseiconXTarget) {  // old version
        this.collapseiconXTarget.classList.remove("rotateX180");
      }
      if (this.hasCollapseiconYTarget) {  // old version
        this.collapseiconYTarget.classList.remove("rotateY180");
      }
      if (this.hasArrowiconYTarget) { // not used
        this.arrowiconYTarget.innerHTML = '<i class="bi bi-caret-right">'
      }
      if (this.hasArrowiconYTarget) {
        this.arrowiconYTarget.innerHTML = '<i class="bi bi-caret-down">'
      }
      if (this.hasCollapsedescriptionTarget) {
        this.collapsedescriptionTarget.textContent = `${this.closeStrValue}`;
      }
    }
    // this.status = !this.status
    // console.log("[setStatus-2] status=%o", this.status);
  }
  close(event) {
    // console.log("[close] %o", this);
    // if (this.hasLevelValue && this.hasGroupValue) {
    //   console.log("this => level: %o, group: %o", this.levelValue, this.groupValue);
    // }
    // // console.log("event origin: %o", event.detail);
    // if (event.detail.hasLevelValue && event.detail.hasGroupValue) {
    //   console.log("event origin => level: %o, group: %o", event.detail.levelValue, event.detail.groupValue);
    // }
    if (this == event.detail) {
      // event issued by myself.
      // console.log("[close] myself return");
      return;
    }
    if ((this.groupValue != event.detail.groupValue) || (this.levelValue == event.detail.levelValue)) {
      if (this.status == true) {
        // console.log("[close] open -> close");
        this.toggle();
        if (this.hasCollapsebodyTarget) {
          // console.log(this.collapsebodyTarget);
          this.collapsebodyTarget.classList.remove("show");
        }
        // else {
        //   console.log("[close] no collapsebodyTarget")
        // }
      }
    }
  }
}
