import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="address"
export default class extends Controller {

  static  targets= [ "zipInput", "adInput" ]

  connect() {
  }

  async zip2ad() {
    const zip = this.zipInputTarget.value
    if (zip.match(/^\d{3}[-]?\d{4}$/)) {
      const request_path = '/addresses/zip2ad?zip=' + zip.replace('-', '')
      const response = await get(request_path, {responseKind: "json"})
      if (response.ok) {
        const body = await response.text
        if (body != "null") {
          this.adInputTarget.value = body
        }
      }
    }
  }
}
