import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="exclusive-element"
export default class extends Controller {

  static  targets= [ "textElement" ]

  connect() {
    const event = new CustomEvent('removeOthers', { detail: this });
    window.dispatchEvent(event);
    // console.log("dispatched event removeOthers")
  }
  remove(event) {
    // console.log("remove")
    if (this == event.detail) {
      // event issued by myself.
      // console.log("[remove] myself return");
      return;
    }
    if (this.hasTextElementTarget) {
      this.textElementTarget.textContent = ""
    }
  }
}
