import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-and-show"
export default class extends Controller {
  static targets = [ "sourceElement", "targetGroup" ]
  static values = { initialSourceStatus: Boolean }

  connect() {
    if (this.hasInitialSourceStatusValue) {
      this.sourceElementTarget.checked = this.initialSourceStatusValue
    }
    this.setTargetGroupVisibility()
  }

  setTargetGroupVisibility() {
    if (this.sourceElementTarget.checked) {
      this.targetGroupTargets.forEach(el => {
        el.hidden = false
      })
    }
    else {
      this.targetGroupTargets.forEach(el => {
        el.querySelectorAll('input[type="text"]').forEach(tx => {
          tx.value = ''
        })
        el.hidden = true
      })
    }
  }
}
