import { Controller } from "@hotwired/stimulus"

// https://discuss.hotwired.dev/t/break-out-of-turbo-frame-on-devise-401-redirect/2479/7

export default class extends Controller {
  intercept(event) {
    const {url} = event.detail.fetchResponse.response;
    // console.log(url)
    if (url.match("/users/sign_in")) {
      event.preventDefault()
      // console.log(window.location.href)
      window.location = window.location.href
    }
  }
}