import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

// Connects to data-controller="location"
export default class extends Controller {
  
  static targets = [ "locationForm", 
    "locationLink", "locationText", "locationPlaceholder",
    "locationAtText", "locationHidden", "locationAtHidden" ]
  static values  = { scheduleId: Number, isStart: Boolean }

  connect() {
  }

  getLocation(event) {
    this.locationTextTarget.innerText = ""
    this.locationLinkTarget.href = ""
    this.locationAtTextTarget.innerText = ""
    if (navigator.geolocation) {
      this.locationPlaceholderTarget.innerText = "位置情報を取得しています"

      navigator.geolocation.getCurrentPosition((position)=> {
        // 緯度、経度
        var geo_ido = position.coords.latitude
        // console.log(geo_ido)
        var geo_keido = position.coords.longitude
        // console.log(geo_keido)
        var location = geo_ido + "," + geo_keido

        // 取得時刻
        // var now_local = new Date(position.timestamp)
        var now_local = new Date()
        // console.log(now_local) // Thu Sep 29 2022 11:31:06 GMT+0900 (日本標準時)
        // console.log(now_local.toLocaleString()) // 2022/9/29 11:31:06

        const diff = now_local.getTimezoneOffset() * 60 * 1000
        // console.log(diff) // -540 * 60 * 1000 = -32400000
        const plusLocal = new Date(now_local - diff)
        // console.log(plusLocal) // Thu Sep 29 2022 20:31:06 GMT+0900 (日本標準時)
        // console.log(plusLocal.toISOString()) // 2022-09-29T11:31:06.148Z
        // console.log(plusLocal.toISOString().slice(0, 19)) // 2022-09-29T11:31:06
        const located_at = plusLocal.toISOString().slice(0, 19)

        // 既存のスケジュールの場合はDBに保存
        if (!isNaN(this.scheduleIdValue)) {
          // 位置情報を保存
          this.save_location(this.scheduleIdValue, this.isStartValue, location, now_local, located_at)
        }
        // 新規作成の場合はここではDBに保存しない。表示のみ
        else {
          this.show_result(location, now_local, located_at)
        }
      }, (error) => {
        // console.log(error.code)
        switch (error.code) {
          case 1: // PERMISSION_DENIED
            this.locationPlaceholderTarget.innerText = "位置情報の取得が許可されていません"
            break;
          case 2: // POSITION_UNAVAILABLE
            this.locationPlaceholderTarget.innerText = "位置情報が取得できませんでした"
            break;
          case 3:  // TIMEOUT
            this.locationPlaceholderTarget.innerText = "位置情報の取得に時間がかかりすぎてタイムアウトしました"
            break;
          default:
            this.locationPlaceholderTarget.innerText = "なんらかのエラーが発生しました"
            break;
        }
      })
      event.preventDefault()
    }
    else {
      // console.log("not supported")
      this.locationPlaceholderTarget.innerText = "お使いのブラウザは GeoLocation API に対応していません"
    }
  }
  async save_location(id, isStart, location, now_local, located_at) {
    const request_path = '/schedules/' + id + '/save_location'
    const response = await patch(request_path, {responseKind: "json", 
              body: JSON.stringify({is_start: isStart, location: location, located_at: located_at})})
    if (response.ok) {
      const body = await response.text
      // console.log("save_location succeeded")
      this.show_result(location, now_local, located_at)
      // イベントを送る(編集画面の裏のカード表示も更新するため)
      const event = new CustomEvent('updateLocation', { detail: this });
      window.dispatchEvent(event);
    }
    else {
      // console.log("save_location failed")
      this.show_error_result()
    }
  }

  // 結果を画面に反映する
  show_result(location, now_local, located_at) {
    // console.log("show result")
    this.locationPlaceholderTarget.innerText = ""
    this.locationLinkTarget.href = "https://www.google.co.jp/maps?q=" + location
    this.locationTextTarget.innerText = location // 表示
    if (this.hasLocationHiddenTarget) {
      this.locationHiddenTarget.value = location // hiddenの値
    }

    // console.log(now_local) // Thu Sep 29 2022 11:31:06 GMT+0900 (日本標準時)
    const now_string = now_local.getFullYear() + "/"
                      + (now_local.getMonth()+1).toString().padStart(2,"0") + "/"
                      + now_local.getDate().toString().padStart(2,"0") + " "
                      + now_local.getHours().toString().padStart(2, "0") + ":"
                      + now_local.getMinutes().toString().padStart(2, "0") + ":"
                      + now_local.getSeconds().toString().padStart(2, "0")
    // console.log(now_string) // 2022/09/29 11:31:06
    this.locationAtTextTarget.innerText = now_string // 表示
    if (this.hasLocationAtHiddenTarget) {
      this.locationAtHiddenTarget.value = located_at // hiddenの値(datetime型)
    }
  }
  show_error_result() {
    // console.log("show_error_result")
    this.locationPlaceholderTarget.innerText = "なんらかのエラーが発生しました"
    this.locationLinkTarget.href = ""
    this.locationTextTarget.innerText = ""
    this.locationHiddenTarget.value = ""
    this.locationAtTextTarget.innerText = ""
    this.locationAtHiddenTarget.value = ""
  }

  updateLocation(event) {
    // 編集画面の裏のカードについて、編集画面で更新された内容に書き換え
    if (this == event.detail) {
      // console.log("[close] myself return");
      return;
    }
    // イベント発行元と同じスケジュールidで、開始/終了が一致する場合に書き換え
    if ((this.scheduleIdValue == event.detail.scheduleIdValue) && (this.isStartValue == event.detail.isStartValue)) {
      this.locationLinkTarget.href = event.detail.locationLinkTarget.href
      this.locationTextTarget.innerText = event.detail.locationTextTarget.innerText
      this.locationAtTextTarget.innerText = event.detail.locationAtTextTarget.innerText
      // console.log("update!")
    }
    // else {
    //   console.log("not matched")
    // }
  }
}
