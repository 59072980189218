import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { Japanese } from "flatpickr/dist/l10n/ja.js"
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = [ "inputDate" ]
  static values = { url: String, defaultDate: String, minDate: String, turboFrame: String }

  connect() {
    flatpickr(this.inputDateTarget, {
      locale: Japanese,
      defaultDate: new Date(this.defaultDateValue),
      minDate: this.minDateValue, // disable before minDate
      disableMobile: true, // use flatpickr on mobile browser, instead of browser's default
      plugins: [
        new monthSelectPlugin({
          shorthand: true, // ex. September -> Sep. (9月)
          dateFormat: 'Y年F', // ex. 2023年1月
          // issue: altInput/altformat not working on monthSelectPlugin 
          // altInput: true,
          // altFormat: 'Y年n月',
        })
      ]
    })
  }
  jump() {
    // console.log(this.urlValue)
    // console.log(this.inputDateTarget.value)
    var yearmonth = this.inputDateTarget.value.match(/^(.*)年.*$/)[1] 
          + '-' + ('0' + this.inputDateTarget.value.match(/^.*年(.*)月$/)[1]).slice(-2)
    // var yearmonth = this.inputDateTarget.value // if no issue on monthSelectPlugin ...
    // console.log(yearmonth)
    const new_url = 
      this.urlValue.replace(/^(.*target_date\=)([0-9]{4}-[0-9]{2})(.*)$/, 
        "$1" + yearmonth + "$3")
    // console.log(new_url)
    Turbo.visit(new_url, { historyChanged: true, frame: this.turboFrameValue })
  }
}
