import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = [ "buttonX", "sidebar" ]
  initialize() {
    this.buttonXTarget.classList.remove("rotateX180");
    this.sidebarTarget.classList.remove("sidebar__menu-body-show");
    this.sidebarTarget.classList.remove("show");
    this.status = false
  }
  connect() {
  }
  toggle() {
    if (this.status == false) {
      // console.log("toggle hide->show")
      this.buttonXTarget.classList.add("rotateX180");
      this.sidebarTarget.classList.add("sidebar__menu-body-show");
    }
    else {
      // console.log("toggle show->hide")
      this.buttonXTarget.classList.remove("rotateX180");
      this.sidebarTarget.classList.remove("sidebar__menu-body-show");
    }
    this.status = !this.status
  }
  hide(event) {
    if (this.status == true) {
      if (event && (this.sidebarTarget.contains(event.target)) || (this.buttonXTarget).contains(event.target)) {
        // console.log("inside!");
        return;
      }
      else {
        // console.log("outside!");
        this.buttonXTarget.classList.remove("rotateX180");
        this.sidebarTarget.classList.remove("sidebar__menu-body-show");
        this.sidebarTarget.classList.remove("show");
        this.status = false;
      }
    }
  }
}
