import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-and-show"
export default class extends Controller {
  static targets = [ "sourceElement", "targetGroup" ]
  static values = { specifiedId: Number }

  connect() {
    this.setTargetGroupVisibility()
  }

  setTargetGroupVisibility() {
    if (this.sourceElementTarget.selectedOptions[0].value == this.specifiedIdValue) {
      this.targetGroupTargets.forEach(el => {
        el.hidden = false
      })
    }
    else {
      this.targetGroupTargets.forEach(el => {
        el.querySelectorAll('input[type="text"]').forEach(tx => {
          tx.value = ''
        })
        el.hidden = true
      })
    }
  }
}
