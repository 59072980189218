import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

// Connects to data-controller="ts--select"
export default class extends Controller {
  static values  = { placeholder: String, confirm: String, closeAfterSelect: Boolean }

  connect() {
    var placeholderStr;
    if (this.hasPlaceholderValue) {
      placeholderStr = `${this.placeholderValue}`;
    }
    else {
      placeholderStr = "選択してください";
    };
    var closeAfter;
    if (this.hasCloseAfterSelectValue) {
      closeAfter = this.closeAfterSelectValue
    }
    else {
      closeAfter = true
    }
    var config = {
      maxOptions: 200, // default: 50
      placeholder: placeholderStr,
      closeAfterSelect: closeAfter,
      plugins: {
        remove_button: {  // 選択された各itemに削除ボタン
          title: '削除',
        },
        // clear_button: {  // 全選択を削除するボタン
        //   title: "全てを削除",
        // },
        checkbox_options: {}, // チェックボックス
        dropdown_input: {}, // 文字入力で絞り込み
      },
      render: {
        no_results: function(data,escape){ // 該当なしの場合
          return '<div class="no-results">該当する選択肢が存在しません</div>';
        },
      },
      onDelete: function(values, event) {
        // want to use 'this.hasConfirmValue' and 'this.confirmValue', like,
        //    if (this.hasConfirmValue) {
        //      return confirm(this.confirmValue)
        //    }
        //    else {
        //      return true
        //    }
        // but, can't use 'this' in this callback function...
        //
        // <select data-controller="ts--select" ... data-ts--select-confirm-value="xxxxxx" ...>
        //   ...   ^^^^^^^^^^^^^^^^^^^^^^^^^^^      ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
        // </select>   <= ts_select_element
        // <div class="ts-wrapper ..." ...>
        //      ^^^^^^^^^^^^^^^^^ <= ts_wwapper_element
        //   <div class="ts-control" ...>
        //        ^^^^^^^^^^^^^^^^^ <= ts_control_element
        //     <div data-value="xx" ... data-ts-item>
        //       ...
        //       <a href="javascript:void(0)", class="remove" ..>x</a>
        //        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ <= event.target
        //     </div>
        //     ...
        //   </div>
        //// <div class="ts-dropdown" ... >
        ////   <div data-value="xx" ... >
        ////     <input type="checkbox"> ... </input> <= event not launched
        //// </div>
        // </div>

        // console.log(event.target)
        var ts_control_element = event.target.closest(".ts-control")
        // if (!ts_control_element) {
        //   ts_control_element = event.target.closest(".ts-dropdown")
        // }
        if (ts_control_element) {
          const ts_wrapper_element = ts_control_element.closest(".ts-wrapper")
          if (ts_wrapper_element) {
            const ts_select_element = ts_wrapper_element.previousElementSibling
            if (ts_select_element && ts_select_element.getAttribute("data-controller") == "ts--select") {
              const confirmStr = ts_select_element.getAttribute("data-ts--select-confirm-value")
              // console.log(confirmStr)
              if (confirmStr) {  // show confrimation dialog
                return confirm(confirmStr)
              }
            }
          }
        }
        return true  // not show confirmation dialog
      }
    }
    new TomSelect(this.element, config)

    // <select> <= this.element
    // 次の <div> に付いている class "form-select" を削除
    this.element.nextSibling.classList.remove("form-select");
  }
}
