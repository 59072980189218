import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="theme"
export default class extends Controller {
  static targets = [ "themeColorHidden", 
                      // "darkModeLight", "darkModeDark", "darkModeSystem"
                    ]
  static values = { originalThemeColor: String, 
                    // originalDarkMode: String
                  }

  initialize() {
    // values で渡されたカラーテーマ、ダークモードに設定する
    // console.log("originalThmeColor: %o", this.originalThemeColorValue);
    // console.log("originalDarkMode: %o", this.originalDarkModeValue);
    this.theme = this.originalThemeColorValue;
    document.documentElement.setAttribute('theme', this.theme);
    // this.darkmode = this.originalDarkModeValue;
    // document.documentElement.setAttribute('darkmode', this.darkmode);
  }
  connect() {
  }
  change_theme() {
    // カラーテーマボタンがクリックされた => カラーテーマを変更
    if (this.theme == "green") {
      this.theme = "purple";
      this.themeColorHiddenTarget.value = "purple";
    }
    else if (this.theme == "purple") {
      this.theme = "pink";
      this.themeColorHiddenTarget.value = "pink";
    }
    else if (this.theme == "pink") {
      this.theme = "green";
      this.themeColorHiddenTarget.value = "green";
    }
    // console.log("themeColorHiddenTarget.value: %o", this.themeColorHiddenTarget.value);
    // console.log("this.theme: %o", this.theme);
    document.documentElement.setAttribute('theme', this.theme);
  }
  // select_darkmode() {
  //   // ダークモードのラジオボタンが選択された => ダークモードを変更
  //   if (this.darkModeLightTarget.checked) {
  //     this.darkmode = "light";
  //   }
  //   else if (this.darkModeDarkTarget.checked) {
  //     this.darkmode = "dark";
  //   }
  //   else if (this.darkModeSystemTarget.checked) {
  //     this.darkmode = "system"
  //   }
  //   // console.log("this.darkmode: %o", this.darkmode);
  //   document.documentElement.setAttribute('darkmode', this.darkmode);
  // }
  cancel() {
    // ユーザー設定画面でキャンセルされた => もとのカラーテーマ、ダークモードに戻す
    // console.log("originalThemeColor: %o", this.originalThemeColorValue);
    document.documentElement.setAttribute('theme', this.originalThemeColorValue);
    // console.log("originalDarkMode: %o", this.originalDarkModeValue);
    // document.documentElement.setAttribute('darkmode', this.originalDarkModeValue);
  }
}
