import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calculate-item-price"
export default class extends Controller {
  static targets = [ "itemSelect", "totalPrice" ]

  connect() {
  }
  calculateTotalPrice() {
    let totalPrice = 0
    Array.from(this.itemSelectTarget.options).forEach(option => {
      if (option.selected) {
        const price_element = document.getElementById("item_code_" + option.value)
        if (price_element != null) {
          // console.log('Price: %o',price_element.dataset.value)
          totalPrice += Number(price_element.dataset.value)
        }
      }
    })
    // console.log(totalPrice)
    this.totalPriceTarget.value = totalPrice
  }
}
