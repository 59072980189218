import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

// Connects to data-controller="save-selected-status"
// This controller has two buttons related to each status.
// User can select one or none of them. User can't select both of them.
// This controller saves clicked value (or undefined value) via ajax.
export default class extends Controller {

  static targets = [ "status1Button", "status2Button" ]
  static values  = {  initialStatus: String, status1Str: String, status2Str: String, statusUndefStr: String,
                      controllerPath: String, methodName: String, id: Number }

  initialize() {
    if (this.initialStatusValue == this.status1StrValue) {
      this.changeButtonToSelected(this.status1ButtonTarget)
      this.changeButtonToUnselected(this.status2ButtonTarget)
    }
    else if (this.initialStatusValue == this.status2StrValue) {
      this.changeButtonToUnselected(this.status1ButtonTarget)
      this.changeButtonToSelected(this.status2ButtonTarget)
    }
    else {
      this.changeButtonToUnselected(this.status1ButtonTarget)
      this.changeButtonToUnselected(this.status2ButtonTarget)
    }
  }
  connect() {
  }

  click(event) {
    const clicked_button = event.target
    var oppsite_button
    var status
    if (clicked_button == this.status1ButtonTarget) {
      oppsite_button = this.status2ButtonTarget
      status = this.status1StrValue
    }
    else {
      oppsite_button = this.status1ButtonTarget
      status = this.status2StrValue
    }
    if (this.isSelectedButton(clicked_button)) {
      this.save_status(this.statusUndefStrValue)
      this.changeButtonToUnselected(clicked_button)
    }
    else {
      this.save_status(status)
      this.changeButtonToSelected(clicked_button)
      if (this.isSelectedButton(oppsite_button)) {
        this.changeButtonToUnselected(oppsite_button)
      }
    }
  }

  isSelectedButton(button) {
    if (button.classList.contains("btn-primary")) {
      return true
    }
    return false
  }
  changeButtonToSelected(button) {
    button.classList.remove("btn-outline-primary")
    button.classList.add("btn-primary")
  }
  changeButtonToUnselected(button) {
    button.classList.remove("btn-primary")
    button.classList.add("btn-outline-primary")
  }

  async save_status(status) {
    const response = await patch(this.controllerPathValue + this.idValue + this.methodNameValue,
                            {responseKind: "json", body: JSON.stringify({id: this.idValue, status: status})})
    if (response.ok) {
      const body = await response.text
    }
  }

}
