import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-and-enable"
export default class extends Controller {

  static targets = ["sourceElement", "targetElement"]

  connect() {
    this.updateTargetElementStatus()
  }

  updateTargetElementStatus() {
    this.targetElementTargets.forEach(el => {
      el.disabled = !this.sourceElementTarget.checked
    })
  }
}