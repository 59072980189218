import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = (metaContent('rails_env') === "development") || (metaContent('rails_env') === 'staging')
window.Stimulus   = application

export { application }

function metaContent (name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element && element.content
}