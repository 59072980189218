import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="schedule-form"
export default class extends Controller {
  static targets = [ "repeatSwitch", "repeatGroup", "singleGroup", "repeatSaveGroup",
                      "repeatAllOne", "repeatAll", "repeatAllReplica",
                      "alldaySwitch", "timeGroup", 
                      "contentSelect", "reportActionGroup", "reportLessonGroup",
                      "formModal", "scheduleGroup",
                    ]
  static values = { action: String, repeat: Boolean, discardurl: String, reportaction: Array, reportlesson: Array }

  connect() {
    // console.log(this.actionValue);
    // console.log(this.repeatValue);
    if (this.actionValue == "edit") {  // 編集の場合は、
      this.repeatSwitchTarget.checked = this.repeatValue; // 繰り返し予定かどうかで繰り返しスイッチon/off
      this.repeatSwitchTarget.disabled = true;  // 繰り返し切り替えスイッチは無効にする
      this.showSingleGroup();  // 単一予定のための要素を表示
      if (this.repeatValue == true) { // 繰り返し予定なら
        this.showRepeatSaveButton();  // この予定のみ/全ての予定のラジオボタンを表示
      }
      else {
        this.hideRepeatSaveButton();  // 繰り返し予定でなければ、この予定のみ/全ての予定のラジオボタンは非表示
      }
    }
    else {  // newの場合は、
      this.showHideRepeatGroup();  // 繰り返しスイッチon/off に応じて表示要素を切り替える
      this.hideRepeatSaveButton();  // この予定のみ/全ての予定のラジオボタンは非表示
    }
    this.toggleTimeTargets(); // 終日設定
    // レポートの表示/非表示
    this.showHideReportGroup();
  }

  // バリデーションエラーなどで再度 render されたときの処理
  reconnect() {
    this.connect()
  }

  // 繰り返し予定
  showHideRepeatGroup() {  // 繰り返しスイッチに応じて、表示する要素を切り替える
    // console.log(this.repeatSwitchTarget);
    if (this.repeatSwitchTarget.checked) {
      // console.log("ON")
      this.showRepeatGroup();
    }
    else {
      // console.log("OFF")
      this.showSingleGroup();
    }
  }
  showRepeatGroup() {  // 繰り返し予定向けの要素を表示する
    // console.log(this.repeatGroupTargets);
    // console.log(this.singleGroupTargets);
    this.repeatGroupTargets.forEach(el => {
      el.hidden = false
    });
    this.singleGroupTargets.forEach(el => {
      el.hidden = true
    });
  }
  showSingleGroup() {  // 単一予定向けの要素を表示する(繰り返しスイッチ無効の場合も)
    this.singleGroupTargets.forEach(el => {
      el.hidden = false
    });
    this.repeatGroupTargets.forEach(el => {
      el.hidden = true
    });
  }
  showRepeatSaveButton() {
    this.repeatSaveGroupTargets.forEach(el => {
      el.hidden = false
    })
  }
  hideRepeatSaveButton() {
    this.repeatSaveGroupTargets.forEach(el => {
      el.hidden = true
    })
  }

  toggleRepeatTargets() {  // 繰り返しスイッチが操作された
    this.showHideRepeatGroup();
  }

  changeRepeatRadio() {
    // 「この予定のみ」「全ての予定」のラジオボタンが変化したら、
    // 削除用の hidden_field の値を更新
    // console.log(this.repeatAllOneTarget.checked);
    // console.log(this.repeatAllTarget.checked);
    if (this.repeatAllTarget.checked) {
      this.repeatAllReplicaTarget.value = true
    }
    else {
      this.repeatAllReplicaTarget.value = false
    }
  }

  // 終日設定
  toggleTimeTargets() {  // 終日設定スイッチが操作された
    // 終日設定スイッチに応じて、開始時刻/終了時刻を表示/非表示する
    if (this.alldaySwitchTarget.checked) {
      // 終日設定オン => 開始時刻/終了時刻の要素を隠す
      this.timeGroupTargets.forEach(el => {
        el.hidden = true
      });
    }
    else {
      // 終日設定オフ => 開始時刻/終了時刻の要素を表示する
      this.timeGroupTargets.forEach(el => {
        el.hidden = false
      });
    }
  }

  // レポート入力を表示/非表示
  showHideReportGroup() {
    let selectOptions = this.contentSelectTarget.selectedOptions;
    let selectArray = [];
    let showReport = false;
    for (let i = 0 ; i < selectOptions.length ; i++){
      // console.log(selectOptions[i].value);
      selectArray.push(parseInt(selectOptions[i].value));
    }
    // console.log(selectArray);

    if (this.hasReportActionGroupTarget) {
      // this.reportActionGroupTarget.hidden = false;
      // showReport = true;

      // console.log(this.reportactionValue);
      var matched = false;
      for (var i = 0, n = this.reportactionValue.length; i < n; ++i) {
        if (selectArray.includes(this.reportactionValue[i])) {
          matched = true;
          break;
        }
      }
      if (matched == true) {
        this.reportActionGroupTarget.hidden = false;
        showReport = true;
      }
      else {
        this.reportActionGroupTarget.hidden = true;
      }
    }
    if (this.hasReportLessonGroupTarget) {
      // console.log(this.reportlessonValue);
      matched = false;
      for (var i = 0, n = this.reportlessonValue.length; i < n; ++i) {
        if (selectArray.includes(this.reportlessonValue[i])) {
          matched = true;
          break;
        }
      }
      if (matched == true) {
        this.reportLessonGroupTarget.hidden = false;
        showReport = true;
      }
      else {
        this.reportLessonGroupTarget.hidden = true;
      }
    }
    if (showReport) {
      this.formModalTarget.classList.add("modal-lg");
      this.scheduleGroupTarget.classList.add("col-lg-6");
    }
    else {
      this.formModalTarget.classList.remove("modal-lg");
      this.scheduleGroupTarget.classList.remove("col-lg-6");
    }
  }
}
