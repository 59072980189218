import { Controller } from "@hotwired/stimulus"

import Masonry from "masonry-layout"

// Connects to data-controller="masonry"
export default class extends Controller {
  static  targets= [  "masonryParent" ]

  connect() {
    this.masonry()

    const observer = new MutationObserver(mutations => {
      // console.log("mutation observer")
      this.masonry()
    })
    observer.observe(this.masonryParentTarget, {
      childList: true,
      subtree: true,
      characterData: true
    })
  }
  masonry() {
    // console.log(this.masonryParentTarget)
    var msnry = new Masonry( this.masonryParentTarget, {
      itemSelector: '.grid-item',
      percentPosition: true,
    })
  }
}
