import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="loading"
export default class extends Controller {
  static targets = [ "loadingElement", "loadingContents" ]

  connect() {
  }
  start() {
    this.loadingElementTarget.classList.remove('loaded')
    this.loadingContentsTarget.classList.add("d-none")
  }
  // loaded() {
  //   this.loadingElementTarget.classList.add('loaded')
  // }
}
